.salesround {
    .main-heading {
        .tokensettingsbtns {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: row;

            .zdgvzfxv{
                white-space: nowrap;
            }

            .inputsizee{
                width: 307px;
            }

            .dropdown {
                max-width: 170px;
                width: 100%;

                .dsfdsf{
                    width: 94px !important;
                    justify-content: space-between;
                }

                .dropdown-toggle {
                    padding: 15px;
                    width: 100%;
                    box-shadow: none;
                    height: 46px;
                    border: 1px solid #fff !important;
                    background: #000 !important;
                    border-radius: 0 !important;
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    &[aria-expanded="true"] {
                        box-shadow: 4px 4px 0px 0px #fff;
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    border: 1px solid #fff;
                    background: #000;
                    box-shadow: 4px 4px 0px 0px #fff;

                    a {
                        padding: 12px 15px;
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border-bottom: 1px solid #f3f3f3;
                        // &:last-child{
                        //     border-bottom: 1px solid transparent;
                        // }
                        background: transparent;
                    }
                }
            }
        }
    }
}


@media (max-width:700px){
    .salesround .main-heading{
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width:600px){
    .salesround .main-heading{
        justify-content: flex-start;
        align-items: flex-start;
    }
    .salesround .main-heading .tokensettingsbtns{
        flex-direction: column;
        width: 100%;
        .custom-option-field{
            width: 100%;
        }
    }
    .salesround .main-heading .tokensettingsbtns .inputsizee{
        width: 100% !important;
    }
    .salesround .main-heading .tokensettingsbtns .dropdown{
        width: 100%;
        max-width: 100%;
    }
    .salesround .main-heading .tokensettingsbtns .dropdown .dsfdsf{
        width: 100% !important;
    }
    .salesround .main-heading .tokensettingsbtns .zdgvzfxv{
        width: 100% !important;
    }
}