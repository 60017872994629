.salesround {
  padding: 60px 0;
  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .parent-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
    }
    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 109.091% */
      letter-spacing: 0.44px;
    }
    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
    }
    .round-setting {
      border: none;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: transparent;
    }
  }
}

.scuhvscycvst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.option-field {
  position: relative;
  .calendar-icon {
    position: absolute;
    top: 45px;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
  }
}
.ijijijijij{
  // pointer-events: none;
  opacity: 0.5;
}
.material-textfield {
  margin-bottom: 14px !important;
}

.active-pag {
  background: #fff !important;
  color: #000 !important;
}

.add-mega-modal .option-field h5 {
  color: #fff;
  margin-bottom: 18px;
  font-size: 12px;
}

.add-mega-modal .option-field {
  margin-bottom: 18px !important;
}

@media (max-width: 991px) {
  .salesround .main-heading button {
    padding: 13px 15px;
    font-size: 14px;
  }
  .salesround .main-heading h6 {
    font-size: 18px;
  }
}

.opacc {
  opacity: 0.4;
  pointer-events: none;
}
