.testnet-round {
  padding-top: 61px;
  padding-bottom: 56px;

  .twice-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;

    .main-heading {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      h6 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.44px;
      }
    }
  }
  .bottom-cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    .single-card {
      flex: 0 0 665px;
      border: 1px solid #fff;
      background: #000;
      box-shadow: 3px 3px 0px 0px #fff;
      padding: 25px;
      min-height: 202px;
      img {
        margin-bottom: 15px;
      }
      p {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
      h6 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.22px;
        margin-top: 10px;
        .green-text {
          color: #28e664;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.18px;
        }
        .red-text {
          color: #fe0000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.18px;
        }
      }
    }
    .set-custom-widthflex{
      flex: 0 0 440px !important;
    }
  }
}

@media (max-width: 1024px) {
  .testnet-round .bottom-cards .single-card {
    flex: 0 0 100%;
    width: 100%;
    min-height: auto;
  }
  .testnet-round .twice-field {
    flex-direction: column;
    gap: 25px;
  }
}
