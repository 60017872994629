.login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-card{
        max-width: 542px;
        width: 100%;
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        padding: 35px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h6{
            color: var(--white, #FFF);
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; /* 24.2px */
            margin: 45px 0;
        }
        .option-field{
            width: 100%;
            position: relative;
            .password-icon{
                position: absolute;
                top: 13px;
                right: 16px;
            }
        }
        .custom-checkbox{
            width: 100%;
            display: block;
            margin-bottom: 45px;
        }
    }
}

.blockuser-modal{
    margin-top: 40px;
    h6{
        color: #FE0000;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; 
        margin-bottom: 20px;
    }
    a{
        color: #FFF;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; 
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:991px){
    .login .login-card{
        padding: 20px;
    }
    .login .login-card h6{
        font-size: 18px;
        text-align: center;
    }
    .login{
        padding-left: 15px;
        padding-right: 15px;
    }
    .blockuser-modal h6{
        font-size: 18px;
    }
    .blockuser-modal a{
        font-size: 18px;
    }
}