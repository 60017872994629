.datatable {
  .main-heading {
    margin: 30px 0;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.44px;
    }
  }

  .table-responsive {
    border: 1px solid #fff;
    background: #000;

    .table {
      margin: 0;
    }

    th {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.28px;
      vertical-align: middle !important;
      border: none;
      padding: 28px 15px;
      position: relative;
      white-space: nowrap;
      background-color: transparent;

      &:last-child {
        text-align: end !important;
        padding-right: 15px;
      }
    }

    td {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
      vertical-align: middle !important;
      border-top: 1px solid #fff;
      padding: 0;
      padding: 30px 15px;
      white-space: nowrap;
      background-color: transparent;

      .forflex {
        display: flex;
        gap: 9px;
        align-items: center;
        position: relative;
        max-width: fit-content;

        .hiddenmain {
          display: none;
          position: absolute;
          top: 30px;
          right: 0px;
          border: 1px solid #fff;
          padding: 16px;
          background: #000;
          box-shadow: 3px 3px 0px 0px #fff;
          z-index: 1;
          flex-direction: column;
          gap: 13px;

          .hiddenflex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 46px;

            .hiddenhead {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
            }

            .hiddenpara {
              color: #fff;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;

              span {
                font-weight: 700;
              }
            }
          }
        }

        .showhiddenmain {
          cursor: pointer;
        }

        .showhiddenmain:hover + .hiddenmain {
          display: flex;
        }
      }

      .twice-text {
        h6 {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          /* 175% */
          letter-spacing: 0.24px;
        }

        p {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          /* 175% */
          letter-spacing: 0.24px;
          margin-top: 2px;
        }
      }

      &:last-child {
        margin-left: auto;
      }

      .forend {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .common-btn {
          padding: 13px 25px;
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
        }
      }
    }

    .footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      // border-top: 1px solid #F2F2F2;
      background-color: transparent;

      .left-f {
        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #a0a1a1;
        }
      }

      .right-f {
        .page-link:focus {
          box-shadow: none;
          background-color: unset;
        }

        .page-link:hover {
          box-shadow: none;
          background-color: unset;
        }

        .page-link {
          border: none;
          color: #a2a4a6;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          /* 15px */
          background-color: transparent;

          &.active {
            background: #fff;
            color: #000;
          }
        }

        .page-item:first-child .page-link {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 100%;
          color: #a0a1a1;
        }
      }
    }
  }

  .ended-text {
    color: #28e664 !important;
  }

  .inprogress {
    color: #ffad4f !important;
  }

  .notstart {
    color: #fe0000 !important;
  }
}

.filter-btn {
  display: flex;
  flex-direction: column;
  width: 11px;
  margin-left: 10px;
}

.parent-tag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dhfvgshdfvhfvdsjfjhf button {
  background-color: #8d0505 !important;
}

@media (max-width: 650px) {
  .table-responsive {
    display: none;
  }

  .viewAll {
    flex-direction: column !important;

    .left-f {
      margin-bottom: 20px;
    }
  }

  .accmblview {
    display: block !important;
    border: 1px solid #fff;
    background: #000;
    backdrop-filter: blur(3px);

    .main-twice-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 28px;
      padding-right: 61px;

      .accmblviewhead {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.28px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .arrowsacc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .accordion {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
      outline: none !important;
      border-radius: none !important;

      .accordion-button::after {
        background: url("../../../../assets/accarrowdown.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 8.571px;
        height: 5.143px;
        position: absolute;
        top: 28px;
        right: 25px;
      }

      .accordion-item {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        outline: none !important;
        border-radius: none !important;

        .accordion-header {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;

          .accordion-button {
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            background: var(--login-bg);
            padding: 22px 25px;
            padding-right: 61px;

            &:focus {
              box-shadow: none;
            }

            .accheadermain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 30px;
              width: 100%;

              .accmainimage {
                width: 30px;
                height: 30px;
                border-radius: 60px;
                background: rgba(0, 0, 0, 0.04);
                object-fit: cover;
                object-position: center;
                display: flex;
                align-items: center;

                .accinnerimg {
                  width: 100%;
                  height: 100%;
                  border-radius: 60px;
                  background: rgba(0, 0, 0, 0.04);
                  object-fit: cover;
                  object-position: center;
                }
              }

              .acctext {
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
              }
            }
          }
        }

        .accordion-body {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;
          padding: 0px 25px;

          .acctexts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0px;
            border-bottom: 1px solid #1c1c1c;

            .forflex {
              display: flex;
              gap: 9px;
              align-items: center;
              position: relative;
              max-width: fit-content;

              p {
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.1px;
              }

              .hiddenmain {
                display: none;
                position: absolute;
                bottom: 30px;
                right: 0px;
                border: 1px solid #fff;
                padding: 16px;
                background: #000;
                box-shadow: 3px 3px 0px 0px #fff;
                z-index: 1;
                flex-direction: column;
                gap: 13px;
                width: 215px;

                .hiddenflex {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .hiddenhead {
                    color: #fff;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                  }

                  .hiddenpara {
                    color: #fff;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;

                    span {
                      font-weight: 700;
                    }
                  }
                }
              }

              .showhiddenmain {
                cursor: pointer;
              }

              .showhiddenmain:hover + .hiddenmain {
                display: flex;
              }
            }

            &:last-child {
              margin-bottom: 0px;
            }

            .textleft {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: 0.28px;
              text-transform: uppercase;
            }

            .textright {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 21px;
              letter-spacing: 0.28px;
            }

            .brdrbtn {
              border: 1px solid #fff;
              background: #000;
              box-shadow: 2px 2px 0px 0px #fff;
              display: flex;
              padding: 7px 15px;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .twice-text {
      h6 {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
      }

      p {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
        margin-top: 2px;
      }
    }
  }

  .accmblview .accordion .accordion-item .accordion-header .accordion-button {
    box-shadow: none !important;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    // border-top: 1px solid #F2F2F2;
    background-color: transparent;

    .left-f {
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }

    .right-f {
      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: #a2a4a6;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 15px */
        background-color: transparent;

        &.active {
          background: #fff;
          color: #000;
        }
      }

      .page-item:first-child .page-link {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }
  }

  .dashboardetail .auctiondetailhead {
    font-size: 20px;
  }
}

.page-item.active {
  .page-link {
    background: #fff;
    border-radius: 0;
    color: black !important;

    &:hover {
      background: #fff !important;
      border-radius: 0 !important;
      color: black !important;
    }

    &:focus {
      background: #fff !important;
      border-radius: 0 !important;
      color: black !important;
    }
  }
}

.viewAll .left-f {
  h6 {
    cursor: pointer !important;
  }
}

.saletokensmodal {
  .modal-dialog {
    max-width: 890px;

    .modal-content {
      .modal-body {
        padding: 40px 0px 0px;

        .saletokenmain {
          display: flex;
          align-items: flex-start;
          gap: 20px;

          .checkboxessmain {
            .checkboxesinner {
              display: flex;
              align-items: flex-start;
              gap: 20px;
            }

            .checkboxessmainpara {
              color: #fff;
              margin-bottom: 12px;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              text-transform: uppercase;
            }
          }

          .option-field {
            width: 100%;
          }

          .dropdown {
            width: 100%;

            .dropdown-toggle {
              border: 1px solid #fff;
              background: #000;
              padding: 17px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 8px;
              color: #343434;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 117%;
              /* 16.38px */
              width: 100%;

              &::after {
                display: none;
              }
            }

            .dropdown-menu {
              border: 1px solid #fff;
              background: #000;
              border-radius: 0px !important;
              padding: 0px;
              min-width: 90px;
              width: 100%;

              a {
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 0.1px;
                display: flex;
                padding: 14px 15px;
                align-items: center;
                border-bottom: 1px solid #f3f3f3;
                gap: 10px;
                background: #000;

                &:hover {
                  background: #0d0d0d !important;
                }

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 120px;
                }
              }
            }
          }
        }

        .addremove {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          .red {
            color: #fe0000;
            justify-content: flex-start;
          }

          .white {
            justify-content: flex-end;
            color: #fff;
          }

          .addremovetexts {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
          }
        }

        .maininputssss {
          display: flex;
          align-items: flex-start;
          gap: 20px;

          .option-field {
            width: 100%;
          }
        }
      }
    }
  }
}

.ysvtvstyvtiivtyvs {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;

  td {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
    padding: 0 !important;
  }
}

@media (max-width: 700px) {
  .saletokensmodal .modal-dialog .modal-content .modal-body .maininputssss {
    flex-direction: column;
  }

  .saletokensmodal .modal-dialog .modal-content .modal-body .saletokenmain {
    flex-wrap: wrap;
  }
}

.datatable .table-responsive td .forflex .showhiddenmain:hover + .hiddenmain {
  &:last-child {
    top: unset !important;
    bottom: 30px;
  }
}
