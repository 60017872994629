.allinstitution-agents{
     .main-heading .rightside-content{
        max-width: 625px !important;
     }
      .viewdetail-tabs{
        opacity: 0;
     }
      .main-heading111 .rightside-content .dropdown{
        max-width: 0 !important;
        width: 0 !important;
      }
       .bottom-cards .single-card{
        flex: 0 0 32.4% !important;
       }
}

@media (max-width:600px){
    .allinstitution-agents .bottom-cards .single-card{
        flex: 0 0 48% !important;
    }
}