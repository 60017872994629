.dashboard {
  padding: 60px 0;
  padding-top: 44px !important;

  .main-heading {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }

    .custom-option-field {
      max-width: 574px;
      width: 100%;
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .single-card {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 3px 3px 0px 0px #fff;
      height: 230px;
      flex: 0 0 32.8%;
      padding: 25px;
      position: relative;

      p {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
        opacity: 0.6;
      }

      h6 {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 26.4px */
        letter-spacing: -0.22px;
        .green-text {
          color: #28e664;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.18px;
        }
        .red-text {
          color: #fe0000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.18px;
        }
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 16.8px */
          letter-spacing: -0.14px;
          margin: 0;

          span {
            background-color: #fff;
            border-radius: 4px;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }
      .custom-tooltip-ico {
        position: absolute;
        top: 40px;
        right: 30px;
      }
      .set-custom-tooltip {
        position: absolute;
        top: 68px;
        right: 28px;
        border: 1px solid #fff;
        background: #000;
        box-shadow: 3px 3px 0px 0px #fff;
        padding: 20px;
        padding-top: 6px;
        max-width: 262px;
        width: 100%;
        z-index: 999;
        .inner-text {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 14px;
          flex-direction: column;
          border-bottom: 1px solid #fff;
          padding-bottom: 16px;
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0px;
          }
          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .bottom-twice {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            margin-top: 10px;
            p {
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 1;
              margin: 0;
              span {
                width: 3px;
                height: 14px;
                display: flex;
                border-radius: 4px;
                background: #fff;
                margin-right: 5px;
              }
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 16.8px */
              letter-spacing: -0.14px;
            }
          }
        }
      }
    }
  }
  .dropdown {
    max-width: 170px;
    width: 100%;

    .dropdown-toggle {
      padding: 15px;
      width: 100%;
      box-shadow: none;
      height: 46px;
      border: 1px solid #fff !important;
      background: #000 !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      &[aria-expanded="true"] {
        box-shadow: 4px 4px 0px 0px #fff;
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;

      a {
        padding: 12px 15px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 1px solid #f3f3f3;
        // &:last-child{
        //     border-bottom: 1px solid transparent;
        // }
        background: transparent;
      }
    }
  }
  .parent-twice-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
}

.mainbuttonsss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h4 {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.22px;
  }

  button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 16px;
  }
}

.mainbuttonssss {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;

  h4 {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.22px;
  }

  button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 16px;
  }
}

.welcomesss {
  color: #fff;
  font-family: Space Mono;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 68.571% */
  letter-spacing: 0.7px;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 15px;
}

@media (max-width: 991px) {
  .welcomesss {
    color: #fff;
    font-family: Space Mono;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.7px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 4px;
  }

  .dashboard .bottom-cards .single-card {
    flex: 0 0 48%;
    height: 200px;
    padding: 15px;
  }

  .dashboard .bottom-cards .single-card p {
    font-size: 12px;
  }

  .dashboard .bottom-cards .single-card h6 {
    font-size: 16px;
  }

  .dashboard .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }

  .dashboard .bottom-cards .single-card .bottom-text {
    gap: 15px;
    flex-wrap: wrap;
  }

  .dashboard .main-heading {
    margin-top: 20px;
  }

  .mainbuttonsss {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  .mainbuttonsss button {
    width: 100%;
  }
  .dashboard .main-heading .custom-option-field {
    max-width: 100%;
  }
  .dashboard .bottom-cards .single-card p {
    word-break: break-all;
  }
}

.dtfdttcdvt6dtvd {
  .single-card {
    flex: 0 0 19.2% !important;
    height: 229px !important;
  }

  .single-card .bottom-text {
    flex-wrap: wrap !important;
  }
}

.newclassagent {
  .single-card {
    flex: 0 0 24.3% !important;
  }
}

@media (max-width: 1300px) {
  .dtfdttcdvt6dtvd {
    .single-card {
      flex: 0 0 18.2% !important;
    }
  }
}

@media (max-width: 991px) {
  .dtfdttcdvt6dtvd .single-card {
    flex: 0 0 48% !important;
  }
}

@media (max-width: 401px) {
  .dashboard .bottom-cards .single-card {
    height: 252px !important;
  }
}

@media (max-width: 376px) {
  .dashboard .bottom-cards .single-card .bottom-text {
    gap: 9px !important;
  }
  .dashboard .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }
}
@media (max-width: 360px) {
  .dashboard .bottom-cards .single-card {
    height: 252px !important;
  }
}

// new ui scss here..................................

.custom-tab-bar {
  border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 331px;
  width: 100%;
  position: relative;
  height: 46px;
  a {
    padding: 6px 11px;
    color: #a2a4a6;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background: #fff;
      color: #000;
      padding: 11px;
    }
  }
}

.cal {
  .rdrCalendarWrapper {
    position: absolute;
    top: 50px;
    z-index: 99;
    border: 1px solid #fff;
    background: #000;
    box-shadow: 4px 4px 0px 0px #fff;
    border-radius: 0;
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
}

.mainbuttonsss button {
  border: none !important;
  color: #f2f2f2 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  letter-spacing: -0.32px;
}
.rdrDayNumber span {
  color: #f2f2f2 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  letter-spacing: -0.32px;
}
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: none !important;
  color: #000 !important;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #000 !important;
}

.dashboard .mainbuttonsss .gusvtsvtvtsvxs {
  display: none !important;
}

@media (max-width: 991px) {
  .custom-tab-bar {
    max-width: 100%;
  }
  .mainbuttonsss button {
    width: auto !important;
  }
  .dashboard .bottom-cards .single-card img {
    display: none !important;
  }
  .dashboard .bottom-cards .single-card {
    height: 100% !important;
    min-height: 170px;
  }
  .dashboard .bottom-cards .single-card p {
    margin-top: 0;
  }
  .dashboard .bottom-cards .single-card h6 span {
    font-size: 12px !important;
  }
  .datatable .main-heading {
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mobile-spec-height {
    min-height: 199px !important;
  }
  .noofleaders-card {
    .bottom-text {
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      transition: 0.3s linear;
    }
    .twice-textbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .showtext {
      height: 100%;
      opacity: 1;
    }
    .hidetext {
      opacity: 0;
      height: 0%;
    }
    .transform-img {
      transform: rotate(180deg);
    }
  }
  .dashboard .bottom-cards .single-card .arrow-down-icon {
    display: block !important;
  }
  .ordersetsm2 {
    order: 2;
  }
  .ordersetsm1 {
    order: 1;
  }
  .new-accordionmobile {
    display: block !important;
    border: 1px solid #fff;
    background: #000;
    .upper-body {
      padding: 22px 25px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      .inner-text {
        flex: 0 0 46%;
        h6 {
          color: #8e8e8e;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 8px;
        }
        p {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }
    }
    .accordion-item {
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      border-radius: 0;
      background: #000;
      .accordion-header {
        width: 100%;
      }
      .accordion-button {
        border-top: 1px solid #fff;
        background: #000;
        border-radius: 0;
        padding: 14px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        box-shadow: none;
        &::after {
          display: none;
        }
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.28px;
        &[aria-expanded="true"] {
          img {
            transform: rotate(180deg);
          }
        }
      }
      .accordion-body {
        background: #000;
        padding: 0px 25px;
        .inner-textt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 20px 0;
          border-bottom: 1px solid #fafafa;
          &:last-child {
            border-bottom: 1px solid transparent;
          }
          p {
            color: #8e8e8e;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 0.24px;
          }
          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
            letter-spacing: 0.28px;
          }
        }
      }
    }
    .accordion-item .accordion-collapse {
      width: 100%;
    }
  }
  .dashboard .mainbuttonsss .gusvtsvtvtsvxs {
    display: block !important;
    border: 1px solid #fff !important;
    background: #000 !important;
    box-shadow: 4px 4px 0px 0px #fff;
    width: 100% !important;
  }
  .dashboard .bottom-cards .single-card .custom-tooltip-ico {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 999;
  }
}

@media (max-width: 600px) {
  .dashboard .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }
  .dashboard .parent-twice-custom {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .dashboard .dropdown {
    max-width: 100%;
  }
  .dashboard .dropdown .dropdown-toggle {
    width: 100% !important;
  }
}

.leader-data {
  margin-top: 40px;
  .leader-heading {
    margin-bottom: 12px;
    h6 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.32px;
    }
  }
  .datatable .table-responsive {
    border: 1px solid #161616 !important;
  }
  .datatable .table-responsive td {
    border-top: 1px solid #161616 !important;
    border-bottom: 1px solid #161616 !important;
  }
}

@media (max-width: 600px) {
  .leader-data .twice-tables-set .datatable .table-responsive {
    display: block !important;
  }
  .leader-data .twice-tables-set {
    flex-direction: row !important;
  }
  .leader-data .twice-tables-set .datatable {
    flex: 0 0 49% !important;
  }
  .leader-data .leader-heading h6 {
    font-size: 14px;
  }
  .leader-data .datatable .table-responsive td {
    padding: 10px !important;
    &:first-child {
      max-width: 10px;
    }
  }
  .leader-data .datatable .table-responsive th {
    display: none !important;
  }
}
